export const $api = <T>(
    url: Parameters<typeof $fetch<T>>[0],
    params?: Parameters<typeof $fetch<T>>[1]
) => {
    const config = useRuntimeConfig()

    const token = useState('authToken')
    if (token.value) {
        params = {
            ...params,
            headers: {
                ...params?.headers,
                'Authorization': `Bearer ${token.value}`
            }
        }
    }

    params = {
        ...params,
        headers: {
            ...params?.headers,
            'ngrok-skip-browser-warning': 'test'
        }
    }

    return $fetch<T>(url, {
        baseURL: config.public.apiBaseUrl,
        ...params
    })
}