import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93uSABYnFuPuMeta } from "/app/pages/suggests/[id].vue?macro=true";
import { default as draft_45_91id_93XUrJ8UR4CpMeta } from "/app/pages/suggests/draft-[id].vue?macro=true";
import { default as indexJB8EbsrOMTMeta } from "/app/pages/suggests/index.vue?macro=true";
import { default as newMNDW1QMRKyMeta } from "/app/pages/suggests/new.vue?macro=true";
export default [
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93uSABYnFuPuMeta?.name ?? "suggests-id",
    path: _91id_93uSABYnFuPuMeta?.path ?? "/suggests/:id()",
    meta: _91id_93uSABYnFuPuMeta || {},
    alias: _91id_93uSABYnFuPuMeta?.alias || [],
    redirect: _91id_93uSABYnFuPuMeta?.redirect,
    component: () => import("/app/pages/suggests/[id].vue").then(m => m.default || m)
  },
  {
    name: draft_45_91id_93XUrJ8UR4CpMeta?.name ?? "suggests-draft-id",
    path: draft_45_91id_93XUrJ8UR4CpMeta?.path ?? "/suggests/draft-:id()",
    meta: draft_45_91id_93XUrJ8UR4CpMeta || {},
    alias: draft_45_91id_93XUrJ8UR4CpMeta?.alias || [],
    redirect: draft_45_91id_93XUrJ8UR4CpMeta?.redirect,
    component: () => import("/app/pages/suggests/draft-[id].vue").then(m => m.default || m)
  },
  {
    name: indexJB8EbsrOMTMeta?.name ?? "suggests",
    path: indexJB8EbsrOMTMeta?.path ?? "/suggests",
    meta: indexJB8EbsrOMTMeta || {},
    alias: indexJB8EbsrOMTMeta?.alias || [],
    redirect: indexJB8EbsrOMTMeta?.redirect,
    component: () => import("/app/pages/suggests/index.vue").then(m => m.default || m)
  },
  {
    name: newMNDW1QMRKyMeta?.name ?? "suggests-new",
    path: newMNDW1QMRKyMeta?.path ?? "/suggests/new",
    meta: newMNDW1QMRKyMeta || {},
    alias: newMNDW1QMRKyMeta?.alias || [],
    redirect: newMNDW1QMRKyMeta?.redirect,
    component: () => import("/app/pages/suggests/new.vue").then(m => m.default || m)
  }
]